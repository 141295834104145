/**
 * Side projects component for my latests projects
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

function SideProjects() {

  const data = useStaticQuery(graphql`
    query {
      dreamweeks: file(absolutePath: { regex: "/dreamweeks_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      codewithbootcamps: file(absolutePath: { regex: "/bootcamps_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      showmethemoney: file(absolutePath: { regex: "/showmethemoney_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      stockideas: file(absolutePath: { regex: "/stock_ideas_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      iic: file(absolutePath: { regex: "/iic_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      funders: file(absolutePath: { regex: "/future_funders_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      founders: file(absolutePath: { regex: "/future_founders_icon.png/" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <div className="side-projects">
       <div className="project">
        <div>
          <Image
            fluid={data.codewithbootcamps.childImageSharp.fluid}
            alt="pink codewithbootcamps logo"
          />
        </div>
        <div>
          <h3>
            <OutboundLink href="https://www.codewithbootcamps.com/" target="_blank">
              codewithbootcamps.com
            </OutboundLink>
          </h3>
          <p>Easily compare the best coding Bootcamps in London.</p>
          <span className="active">active</span><span className="date">2019</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.stockideas.childImageSharp.fluid}
            alt="stockideas logo"
          />
        </div>
        <div>
          <h3>
            <OutboundLink href="https://www.stockideas.club/" target="_blank">
              stockideas.club
            </OutboundLink>
          </h3>
          <p>Deep-dives of the fastest-growing companies and their stocks. Emailed every other Sunday to over 200 people investing in their future.</p>
          <span className="active">active</span><span className="date">2020</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.showmethemoney.childImageSharp.fluid}
            alt="showmethemoney moneybag emoji logo"
          />
        </div>
        <div>
          <h3>
            <OutboundLink href="https://www.showmethemoney.club/" target="_blank">
              showmethemoney.club
            </OutboundLink>
          </h3>
          <p>A fun and visual way to see how investing can build wealth for the long term. This started as a weekend project that was built in public.</p>
          <span className="active">active</span><span className="date">2020</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.iic.childImageSharp.fluid}
            alt="orange iic logo"
          />
        </div>
        <div>
          <h3>
            {/* <OutboundLink href="https://www.codewithbootcamps.com/" target="_blank">
              codewithbootcamps.com
            </OutboundLink> */}
            Individual Investors Club
          </h3>
          <p>A Slack community for individual investors, sharing ideas, tips and learnings.</p>
          <span className="active">active</span><span className="date">2020</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.dreamweeks.childImageSharp.fluid}
            alt="red dreamweeks logo"
          />
        </div>
        <div>
          <h3>dreamweeks.co</h3>
          <p>A time visualisation tool, built on top of Google Calendar.</p>
          <span className="inactive">inactive</span><span className="date">2018</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.founders.childImageSharp.fluid}
            alt="orange for future founders logo"
          />
        </div>
        <div>
          <h3>
            <OutboundLink href="https://www.forfuturefounders.com/" target="_blank">
              forfuturefounders.com
            </OutboundLink>
          </h3>
          <p>A collection of resources for inspiring founders.</p>
          <span className="inactive">inactive</span><span className="date">2011</span>
        </div>
      </div>

      <div className="project">
        <div>
          <Image
            fluid={data.funders.childImageSharp.fluid}
            alt="blue for future funders logo"
          />
        </div>
        <div>
          <h3>
            <OutboundLink href="https://www.forfuturefunders.com/" target="_blank">
              forfuturefunders.com
            </OutboundLink>
          </h3>
          <p>A collection of resources for inspiring VCs.</p>
          <span className="inactive">inactive</span><span className="date">2011</span>
        </div>
      </div>

    </div>
  )
}

export default SideProjects
