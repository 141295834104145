import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SideProjects from "../components/sideProjects"

class Projects extends React.Component {

  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    

    return (
      <Layout location={location} title={siteTitle}>
        <SEO 
          title="Side Projects" 
          description="Things I've designed, built and launched."
        />

        <h1 className="page-header">Side Projects</h1>
        <SideProjects />
        
      </Layout>
    )
  }
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
